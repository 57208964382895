import { gql } from '@apollo/client';
import CommonFragments from './common.fragment';

const SAVE_OPPORTUNITY_FRAGMENT = gql`
  fragment saveOpportunity on Opportunity {
    id
    title
    description
    venue
    city
    deadline_date
    start_date
    end_date
    organisation
    street
    type
    genres
    instruments
    target
    experience_level
    languages
    max_age
    min_age
    media
    video_links
  }
`;

export const OPPORTUNITY_PAYMENT_FRAGMENT = gql`
  fragment opportunityPayment on Opportunity {
    payment_details {
      is_used
      cost
      code
      currency
      slots
      title
      description
    }
    payment_options
    is_paid
  }
`;

export const OPPORTUNITY_MINI_FRAGMENT = gql`
  fragment opportunityMini on Opportunity {
    id
    title
    slug
    target
    summary
    start_date
    end_date
    deadline_date
    is_virtual
    min_age
    max_age
    user{
      id
    }
    event_types {
      name
    }
    logo {
      name
      path
      placeholder
      thumb
    }
    location {
      ...locationInfo
    }
    approval_type
    organisation {
      id
      slug
      name
      logo {
        thumb
      }
      city {
        name
        country {
          name
        }
      }
    }
    ...opportunityPayment
  }
  ${OPPORTUNITY_PAYMENT_FRAGMENT}
  ${CommonFragments.locationInfo}
`;

export const OPPORTUNITY_CARD_FRAGMENT = gql`
  fragment opportunityCard on Opportunity {
    id
    title
    slug
    description
    is_external
    external_link
    summary
    approval_type
    organisation {
      id
      slug
      name
      logo {
        thumb
      }
      teamMembers {
        id
        name
        email
        role
        user {
          id
          name
          email
        }
      }
    }
    event_type {
      id
      name
      slug
      created_at
    }
    logo {
      name
      path
      thumb
      placeholder
      responsive_set {
        path
        width
      }
    }
    genres {
      id
      name
    }
    event_types {
      id
      name
    }
    user {
      id
    }
    location {
      ...locationInfo
    }
    is_virtual
    start_date
    end_date
    deadline_date
    views {
      id
    }
    likes {
      id
      user {
        id
        name
        email
      }
    }
    feedback_form {
      id
      # fields {
      #   id
      #   label
      #   type
      #   order
      #   subtype
      #   is_required
      #   choices
      #   child_fields {
			# 		id
      #     label
      #   	slug
      #     type
      #   }
      # }
      # opportunity {
      #   id
      # }
      # supported_languages
      # is_active
      # is_skipped
      # max_members
    }
    ...opportunityPayment
  }
  ${CommonFragments.locationInfo}
  ${OPPORTUNITY_PAYMENT_FRAGMENT}
`;

export const OPPORTUNITY_REVIEWER_FRAGMENT = gql`
  fragment opportunityReviewer on Opportunity {
    reviewers {
      id
      name
      email
      avatar {
        name
        placeholder
        thumb
        path
      }
      role {
        name
        display_name
      }
    }
  }
`;

export const COUPON_MINI = gql`
  fragment couponMini on Coupon {
    id
    name
    code
    value
    slots
    is_active
  }
`;

export const OPPORTUNITY_FULL_DETAILS = gql`
  fragment opportunityDetails on Opportunity {
    ...opportunityCard
    call_for_papers
    is_external
    external_link
    supporters {
      id
      name
      description
      logo {
        name
        path
        thumb
      }
      is_featured
      website
      type
    }
    practical_information {
      title
      description
    }
    subdomain {
      id
      name
      slug
      domain {
        id
        name
        slug
        created_at
      }
      created_at
    }
    domainkeywords
    description
    event_type_series
    seniority_levels
    languages {
      id
      name
    }
    gallery {
      name
      path
      placeholder
      thumb
    }
    target_countries {
      id
      name
    }
    logo {
      name
      path
      placeholder
      thumb
    }
    summary
    time_visibility {
      start_time
      end_time
      deadline_time
    }
    target
    experience_level
    created_at
    max_age
    min_age
    is_private
    is_virtual
    is_closed
    approval_type
    video_links
    time_zone
    committee_members {
      id
      name
      email
      status
      roles {
        committee_group
        track_id
        role
      }
    }
    speakers {
      id
      name
      email
      bibliography
      affiliation
      website
    }
    # registration_form {
    #   id
    #   fields {
    #     id
    #     label
    #     type
    #     order
    #     slug
    #     subtype
    #     is_required
    #   }
    #   terms
    #   supported_languages
    #   is_active
    #   is_skipped
    #   max_members
    # }
    # submission_form {
    #   fields {
    #     id
    #     label
    #     order
    #     slug
    #     subtype
    #     is_required
    #   }
    #   terms
    #   supported_languages
    #   is_active
    # }
    # review_form {
    #   id
    #   fields {
    #     id
    #     label
    #   }
    # }
    important_dates {
      date
      title
    }
    event_bool_settings {
      blinded_event
      all_pc_members
    }
    tracks {
      id
      track_id
      track_name
      track_description
      track_admin
      slug
      track_domain_areas {
        id
        name
        slug
        domain {
          id
          name
          slug
          created_at
        }
        created_at
      }
      track_keywords
      track_start_date
      track_end_date
      addTrackAdministrator
      track_important_dates {
        date
        title
      }
      submission_form {
        id
        terms
        fields {
          id
          label
          type
          order
          slug
          subtype
          is_required
          choices
          child_fields {
            id
            label
            slug
            type
          }
          grades {
            id
            grade
            label
          }
        }
        submission_instructions
        supported_languages
        is_active
        is_skipped
        max_members
      }
      review_form {
                id
        fields {
          id
          label
          type
          order
          slug
          subtype
          is_required
          choices
          child_fields {
            id
            label
            slug
            type
          }
          grades {
            id
            grade
            label
          }
        }
        supported_languages
        is_active
        is_skipped
        max_members
      }
      created_at
    }
    reviewers {
        id
        name
        email
        avatar {
          path
          thumb
        }
        locale
        role {
          name
          display_name
        }
        is_verified
        has_reviews
        selected_type
        is_scientist
      }
      views {
        id
      }
      likes {
        id
        user {
          id
          name
          email
        }
      }
    ...opportunityPayment
  }
  ${OPPORTUNITY_PAYMENT_FRAGMENT}
  ${OPPORTUNITY_CARD_FRAGMENT}
`;

const OpportunityFragment = {
  saveOpportunity: SAVE_OPPORTUNITY_FRAGMENT,
  opportunityCard: OPPORTUNITY_CARD_FRAGMENT,
  opportunityReviewer: OPPORTUNITY_REVIEWER_FRAGMENT,
  opportunityPayment: OPPORTUNITY_PAYMENT_FRAGMENT,
  opportunityMini: OPPORTUNITY_MINI_FRAGMENT,
  couponMini: COUPON_MINI,
  opportunityDetails: OPPORTUNITY_FULL_DETAILS,
};

export default OpportunityFragment;
